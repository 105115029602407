import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

export default class ModernBlockchain extends Component {
    scrollWithOffset = (e) => {
      const el = document.getElementById(e.target.getAttribute('data-id'))
      window.scrollTo({
          top: el.offsetTop + el.offsetHeight + 150,
          behavior: "smooth"
        });
    }

    render() {
        return (
            <React.Fragment>
                <Container>
                <Row className="justify-content-center">
                    <Col className="col-12">
                        <div className="section-title text-center mb-4 pb-2">
                            <h4 className="title mb-4">Crafting Tomorrow's Digital Realities Today</h4>
                            <h5 className="text-muted para-desc mx-auto mb-0">We help you rise above the rest and elevate your digital solutions to new heights.</h5>
                        </div>
                    </Col>
                </Row>

                    <Row className="justify-content-center">
                        <Col lg={4} md={6} className="mt-4 pt-2">
                            <Card className="border-0 features feature-clean course-feature p-4 overflow-hidden shadow text-center">
                                <div className="icons text-primary text-center mx-auto">
                                    <i className="uil uil-laptop-cloud d-block rounded h3 mb-0"></i>
                                </div>
                                <CardBody className="p-0 mt-4">
                                    <Link to="#" className="title h5 text-dark">Web Applications</Link>
                                    <p className="text-muted mt-2">Crafting Custom Web Applications and Streaming Data Solutions.</p>
                                    <a onClick={el => this.scrollWithOffset(el)} data-id="web" className="text-primary read-more">Read More <i className="uil uil-angle-right-b"></i></a>
                                    <i className="uil uil-laptop-cloud text-primary full-img"></i>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={4} md={6} className="mt-4 pt-2">
                            <Card className="border-0 features feature-clean course-feature p-4 overflow-hidden shadow text-center">
                                <div className="icons text-primary text-center mx-auto">
                                    <i className="uil uil-mobile-android d-block rounded h3 mb-0"></i>
                                </div>
                                <CardBody className="p-0 mt-4">
                                    <Link to="#" className="title h5 text-dark">App Development</Link>
                                    <p className="text-muted mt-2">Transforming Ideas into Intuitive Mobile Experiences.</p>
                                    <a onClick={el => this.scrollWithOffset(el)} data-id="app" className="text-primary read-more">Read More <i className="uil uil-angle-right-b"></i></a>
                                    <i className="uil uil-mobile-android text-primary full-img"></i>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={4} md={6} className="mt-4 pt-2">
                            <Card className="border-0 features feature-clean course-feature p-4 overflow-hidden shadow text-center">
                                <div className="icons text-primary text-center mx-auto">
                                    <i className="uil uil-circuit d-block rounded h3 mb-0"></i>
                                </div>
                                <CardBody className="p-0 mt-4">
                                    <Link to="#" className="title h5 text-dark">Generative AI</Link>
                                    <p className="text-muted mt-2">Redefining Boundaries with AI-Driven Innovation.</p>
                                    <a onClick={el => this.scrollWithOffset(el)} data-id="genai" className="text-primary read-more">Read More <i className="uil uil-angle-right-b"></i></a>
                                    <i className="uil uil-circuit text-primary full-img"></i>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={4} md={6} className="mt-4 pt-2">
                            <Card className="border-0 features feature-clean course-feature p-4 overflow-hidden shadow text-center">
                                <div className="icons text-primary text-center mx-auto">
                                    <i className="uil uil-presentation-line d-block rounded h3 mb-0"></i>
                                </div>
                                <CardBody className="p-0 mt-4">
                                    <Link to="#" className="title h5 text-dark">Data Visualization</Link>
                                    <p className="text-muted mt-2">Your Story, Illuminated: Transforming Complex Data into Visual Insights.</p>
                                    <a onClick={el => this.scrollWithOffset(el)} data-id="data" className="text-primary read-more">Read More <i className="uil uil-angle-right-b"></i></a>
                                    <i className="uil uil-presentation-line text-primary full-img"></i>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={4} md={6} className="mt-4 pt-2">
                            <Card className="border-0 features feature-clean course-feature p-4 overflow-hidden shadow text-center">
                                <div className="icons text-primary text-center mx-auto">
                                    <i className="uil uil-amazon d-block rounded h3 mb-0"></i>
                                </div>
                                <CardBody className="p-0 mt-4">
                                    <Link to="#" className="title h5 text-dark">Motion Graphics & Logo Creation</Link>
                                    <p className="text-muted mt-2">Elevate Your Brand with Dynamic Motion Graphics and Stunning Logo Creations.</p>
                                    <a onClick={el => this.scrollWithOffset(el)} data-id="design" className="text-primary read-more">Read More <i className="uil uil-angle-right-b"></i></a>
                                    <i className="uil uil-amazon text-primary full-img"></i>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={4} md={6} className="mt-4 pt-2">
                            <Card className="border-0 features feature-clean course-feature p-4 overflow-hidden shadow text-center">
                                <div className="icons text-primary text-center mx-auto">
                                    <i className="uil uil-play-circle d-block rounded h3 mb-0"></i>
                                </div>
                                <CardBody className="p-0 mt-4">
                                    <Link to="#" className="title h5 text-dark">3D Modeling, Animation, and VFX</Link>
                                    <p className="text-muted mt-2">Bringing Imagination to Life: Crafing worlds through 3D Modeling, Animation, and VFX.</p>
                                    <a onClick={el => this.scrollWithOffset(el)} data-id="vfx" className="text-primary read-more">Read More <i className="uil uil-angle-right-b"></i></a>
                                    <i className="uil uil-play-circle text-primary full-img"></i>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}
