import React, { Component } from 'react'

//import images
import crypto1 from '../../assets/images/crypto/smartgeo.png';
import crypto2 from '../../assets/images/crypto/map.png';
import genai from '../../assets/images/genai_1000.jpg';
import data from '../../assets/images/Centra_2019_Reel_UpThere_DataVis_v01.mp4';
import motion from '../../assets/images/Centra_2019_Reel_UpThere_MoGraph_v01.mp4';
import video2 from '../../assets/images/3d_Reel_UpThere_v01.mp4';
import { Card, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class Team extends Component {
    handleVideoMounted = element => {
      if (element !== null) {
        element.currentTime = 1;
      }
    };

    render() {
        return (
            <React.Fragment>
                <Container className="mt-100 mt-60" id="web">
                    <Row className="align-items-center">
                        <Col md={{ size: 6, order: 1}} sm={{ size: 12, order: 2}} xs={{ size: 12, order: 2}} className="mt-4 pt-2 mt-lg-0 pt-lg-0">
                            <Card className="border-0 shadow rounded ms-lg-4 overflow-hidden">
                                <div className="d-flex p-2 bg-light justify-content-between align-items-center">
                                    <div>
                                        <Link to="#" className="text-danger"><i className="mdi mdi-circle"></i></Link>{" "}
                                        <Link to="#" className="text-warning"><i className="mdi mdi-circle"></i></Link>{" "}
                                        <Link to="#" className="text-success"><i className="mdi mdi-circle"></i></Link>{" "}
                                    </div>

                                    <small className="fw-bold">SMARTGeo</small>
                                </div>
                                <div className="bg-light px-2 position-relative">
                                    {/*<video className="w-100 rounded" controls autoPlay loop>
                                        <source src={video} type="video/mp4" />
                                    </video>*/}
                                    <img src={crypto1} className="img-fluid" alt=""/>
                                </div>

                                <div className="p-2 bg-light">
                                    {/*<p className="text-muted mb-0"><b>Note:</b> This video is only for demo purposes.</p>*/}
                                </div>
                            </Card>
                        </Col>

                        <Col md={{ size: 6, order: 2}} sm={{ size: 12, order: 1}} xs={{ size: 12, order: 1}} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title ms-lg-5">
                                <h4 className="title mb-4">Web Applications</h4>
                                <p className="text-muted">Up There's web application development services are crafted with a focus on innovation, functionality, and user experience. We specialize in creating cutting-edge web solutions tailored to meet the unique needs of our clients. Our team of skilled developers utilizes the latest technologies and industry best practices to deliver high-quality, scalable web applications that drive business growth and success.</p>
                                <ul className="list-unstyled text-muted">
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>B2B & B2C Solutions</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Streaming Data Pipelines & Enrichments</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Data Dashboards</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Marketplaces</li>
                                </ul>
                                {/*<Link to="#" className="mt-3 h6 text-primary">Find Out More <i className="uil uil-angle-right-b"></i></Link>*/}
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className="mt-100 mt-60" id="app">
                    <Row className="align-items-center">
                        <Col lg={7} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title me-lg-5">
                                <h4 className="title mb-4">App Development</h4>
                                <p className="text-muted">We understand that mobile apps play a crucial role in today's digital ecosystem, offering unparalleled opportunities for businesses to engage with their audience and drive growth. Whether it's iOS, Android, or cross-platform development, we have the expertise to bring your ideas to life and launch successful mobile applications that resonate with your target audience.</p>
                                <ul className="list-unstyled text-muted">
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>React Native, Cross-Platform Support</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Scalability and Performance Optimization</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Robust Security Measures</li>
                                </ul>
                                {/*<Link to="#" className="mt-3 h6 text-primary">Find Out More <i className="uil uil-angle-right-b"></i></Link>*/}
                            </div>
                        </Col>

                        <Col lg={5} md={6} className="">
                            <img src={crypto2} className="img-fluid" alt=""/>
                        </Col>
                    </Row>
                </Container>

                <Container className="mt-100 mt-60" id="genai">
                    <Row className="align-items-center">
                        <Col md={{ size: 6, order: 1}} sm={{ size: 12, order: 2}} xs={{ size: 12, order: 2}} className="mt-4 pt-2 mt-lg-0 pt-lg-0">
                            <Card className="border-0 shadow rounded ms-lg-4 overflow-hidden">
                                <img src={genai} className="img-fluid" alt=""/>
                            </Card>
                        </Col>

                        <Col md={{ size: 6, order: 2}} sm={{ size: 12, order: 1}} xs={{ size: 12, order: 1}} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title ms-lg-5">
                                <h4 className="title mb-4">Generative AI</h4>
                                <p className="text-muted">At Up There, we are committed to pushing the boundaries of what's possible with Generative AI, enabling our clients to unlock new levels of creativity, efficiency, and personalization in their digital initiatives. Whether it's automating content generation, summarizing disparate data sources, or enhancing personalization capabilities, we tailor our GenAI solutions to meet the requirements of each client.</p>
                                <ul className="list-unstyled text-muted">
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Custom-Trained Models</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Summarize Proprietary Data Sources</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Cross-Language Topic, Sentiment & Named Entity Recognition</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Chatbots</li>
                                </ul>
                                {/*<Link to="#" className="mt-3 h6 text-primary">Find Out More <i className="uil uil-angle-right-b"></i></Link>*/}
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className="mt-100 mt-60" id="data">
                    <Row className="align-items-center">
                        <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title ms-lg-5">
                                <h4 className="title mb-4">Data Visualization</h4>
                                <p className="text-muted">We specialize in creating tailored data visualization solutions that cater to your unique needs and objectives. Whether you're looking to analyze complex datasets, track key performance indicators, or communicate insights effectively, our team crafts intuitive visualizations that bring clarity to your data.</p>
                                <ul className="list-unstyled text-muted">
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Custom Visualization Solutions</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Interactive Dashboards</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Data Storytelling</li>
                                </ul>
                                {/*<Link to="#" className="mt-3 h6 text-primary">Find Out More <i className="uil uil-angle-right-b"></i></Link>*/}
                            </div>
                        </Col>
                        <Col lg={6} md={6} className="mt-4 pt-2 mt-lg-0 pt-lg-0">
                            <Card className="border-0 shadow rounded ms-lg-4 overflow-hidden">
                                <div className="d-flex p-2 bg-light justify-content-between align-items-center">
                                    <div>
                                        <Link to="#" className="text-danger"><i className="mdi mdi-circle"></i></Link>{" "}
                                        <Link to="#" className="text-warning"><i className="mdi mdi-circle"></i></Link>{" "}
                                        <Link to="#" className="text-success"><i className="mdi mdi-circle"></i></Link>{" "}
                                    </div>
                                </div>
                                <div className="bg-light px-2 position-relative">
                                    <video className="w-100 rounded" controls ref={this.handleVideoMounted}>
                                        <source src={data} type="video/mp4" />
                                    </video>
                                </div>

                                <div className="p-2 bg-light">
                                    {/*<p className="text-muted mb-0"><b>Note:</b> This video is only for demo purposes.</p>*/}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Container className="mt-100 mt-60" id="design">
                    <Row className="align-items-center">
                        <Col md={{ size: 6, order: 1}} sm={{ size: 12, order: 2}} xs={{ size: 12, order: 2}} className="mt-4 pt-2 mt-lg-0 pt-lg-0">
                            <Card className="border-0 shadow rounded ms-lg-4 overflow-hidden">
                                <div className="d-flex p-2 bg-light justify-content-between align-items-center">
                                    <div>
                                        <Link to="#" className="text-danger"><i className="mdi mdi-circle"></i></Link>{" "}
                                        <Link to="#" className="text-warning"><i className="mdi mdi-circle"></i></Link>{" "}
                                        <Link to="#" className="text-success"><i className="mdi mdi-circle"></i></Link>{" "}
                                    </div>
                                </div>
                                <div className="bg-light px-2 position-relative">
                                    <video className="w-100 rounded" controls ref={this.handleVideoMounted}>
                                        <source src={motion} type="video/mp4" />
                                    </video>
                                </div>

                                <div className="p-2 bg-light">
                                    {/*<p className="text-muted mb-0"><b>Note:</b> This video is only for demo purposes.</p>*/}
                                </div>
                            </Card>
                        </Col>

                        <Col md={{ size: 6, order: 2}} sm={{ size: 12, order: 1}} xs={{ size: 12, order: 1}} className="-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title ms-lg-5">
                                <h4 className="title mb-4">Motion Graphics & Logo Creation</h4>
                                <p className="text-muted">From dynamic motion design to captivating motion graphics and timeless logo design, we're dedicated to helping your brand stand out in today's competitive landscape.</p>
                                <ul className="list-unstyled text-muted">
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Motion Design</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Motion Graphics</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Logo Design & Animation</li>
                                </ul>
                                {/*<Link to="#" className="mt-3 h6 text-primary">Find Out More <i className="uil uil-angle-right-b"></i></Link>*/}
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className="mt-100 mt-60" id="vfx">
                    <Row className="align-items-center">
                        <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title ms-lg-5">
                                <h4 className="title mb-4">3D Modeling, Animation, and VFX</h4>
                                <p className="text-muted">At Up There, we blend artistry with technology to craft immersive experiences that transcend reality, empowering you to unleash your imagination like never before.</p>
                                <ul className="list-unstyled text-muted">
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>3D Modeling</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Animation</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>VFX (Visual Effects)</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Real-Time Rendering</li>
                                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>VR (Virtual Reality)</li>
                                </ul>
                                {/*<Link to="#" className="mt-3 h6 text-primary">Find Out More <i className="uil uil-angle-right-b"></i></Link>*/}
                            </div>
                        </Col>

                        
                        <Col lg={6} md={6} className="mt-4 pt-2 mt-lg-0 pt-lg-0">
                            <Card className="border-0 shadow rounded ms-lg-4 overflow-hidden">
                                <div className="d-flex p-2 bg-light justify-content-between align-items-center">
                                    <div>
                                        <Link to="#" className="text-danger"><i className="mdi mdi-circle"></i></Link>{" "}
                                        <Link to="#" className="text-warning"><i className="mdi mdi-circle"></i></Link>{" "}
                                        <Link to="#" className="text-success"><i className="mdi mdi-circle"></i></Link>{" "}
                                    </div>
                                </div>
                                <div className="bg-light px-2 position-relative">
                                    <video className="w-100 rounded" controls loop>
                                        <source src={video2} type="video/mp4" />
                                    </video>
                                </div>

                                <div className="p-2 bg-light">
                                    {/*<p className="text-muted mb-0"><b>Note:</b> This video is only for demo purposes.</p>*/}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}
