import React, { Component } from 'react'
import { Col, Container, Form, Row ,Button } from 'reactstrap';

import cta from '../../assets/images/crypto/bg.jpg';
import cta1 from '../../assets/images/crypto/pass.png';

export default class Cta extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section" 
                style={{ background: `url(${cta}) center ` }}
                >
                    <div className="bg-overlay bg-primary bg-gradient" style={{ opacity : '0.7'}}></div>
                    <Container>
                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <div className="section-title text-center">
                                    <h4 className="title text-white title-dark mb-4">Ready to Get Started?</h4>
                                    <p className="text-light para-dark para-desc mx-auto">Drop Us a Line and Let's Create Something Amazing!</p>
                                    <a href="mailto:mike.lawler@uptheresoftware.com" target="_blank" style={{'color': '#2f55d4'}} class="btn btn-pills btn-light mt-2 me-2">Contact Us</a>
                                </div>
                                
                                <div className="mt-4 pt-2">
                                   {/* <img src={cta1} className="img-fluid d-block mx-auto" alt="" />*/}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
