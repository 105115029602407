import React from 'react'
import { Navigate } from "react-router-dom";

//Main Index
import Main from "../pages/Home/indexMain";

const routes = [

  //Index Main
  {
    path: "/",
    exact: true,
    component: <Navigate to="/index" />,
  },
  { path: "/index", component: <Main />},

];

export default routes;
